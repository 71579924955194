@page {
    size: A5;
    margin: 0mm; /* this affects the margin in the printer settings */
}

@media print {
    @page {
        margin: 0;
    }
    body {
        margin: 1.6cm;
    }
}

@media (min-width: 820px) {
    html,
    body,
    #root {
        overflow-x: hidden;
    }
}
